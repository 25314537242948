import React from 'react'
import { useDispatch, useSelector } from "react-redux";

import { HideDialog } from "../store/modal/modal.actions";

const success = require("./../images/success.svg");
const cross = require("./../images/icon/cross.svg");

function Dialog() {
  const dispatch = useDispatch();
  return (
    <div className="assignment-dialog" onClick={() => dispatch(HideDialog(false))}>
      <div
        className="assignment-dialog__container"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="assignment-dialog__head">
          <img className="assignment-dialog__successIcon" src={success} alt="success" />
          <button
            className="assignment-dialog__headIcon"
            onClick={() => dispatch(HideDialog(false))}
          >
            <img src={cross} alt="cross-icon" />
          </button>
          <h2 className="assignment-dialog__head__title">
            Successfully Save
          </h2>
        </div>
        <div className="assignment-dialog__footer">
          <button
            className="assignment-dialog__footer__btn"
            onClick={() => dispatch(HideDialog(false))}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
}

export default Dialog;
