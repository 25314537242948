import { assignment as types } from "../../types";
import * as actions from "../../types/assignment/actions.types";

export const SetJobTitle = (
  payload: types.State["jobTitle"]
): actions.SetJobTitle => ({
  type: types.actionsTypes.SET_JOB_TITLE,
  payload,
});

export const SetCandidate = (
  payload: types.State["candidate"]
): actions.SetCandidate => ({
  type: types.actionsTypes.SET_CANDIDATE,
  payload,
});

export const SetAssessmentType = (
  payload: types.State["assessmentType"]
): actions.SetAssessmentType => ({
  type: types.actionsTypes.SET_ASSESSMENT_TYPE,
  payload,
});

export const SetAssessmentList = (
  payload: types.State["assessmentsList"]
): actions.SetAssessmentList => ({
  type: types.actionsTypes.SET_ASSESSMENT_LIST,
  payload,
});

export const SetReviewer = (
  payload: types.State["reviewers"]
): actions.SetReviewer => ({
  type: types.actionsTypes.SET_REVIEWER,
  payload,
});

export const SetDeadline = (
  payload: types.State["deadline"]
): actions.SetDeadline => ({
  type: types.actionsTypes.SET_DEADLINE,
  payload,
});

export const ResetState = ():
 actions.ResetState => ({
  type: types.actionsTypes.RESET_STATE,
});
